/**@jsx jsx */
import { jsx, Box, Container, Heading, Text } from "theme-ui";
import lgpdImg from 'assets/images/lgpdImage.jpg';

const LgpdContent = () => {
    return(
        <section id='lgpd'>
            <Container sx={styles.container}>
                <Box as='figure' sx={styles.lgpdImage}>
                    <img src={lgpdImg} alt='lgpd'/>
                </Box>
                <Box>
                    <Heading as='h1' sx={styles.header}>
                        A MOU
                    </Heading>
                </Box>
                <Box sx={styles.textContent}>
                    <Text>
                    Nosso propósito é contribuir para que as pessoas e as empresas possam 
                    desenvolver suas ideias e projetos da melhor maneira possível, e que esta relação esteja constantemente pautada pela ética, 
                    transparência e responsabilidade entre as partes.
                    </Text>
                </Box>
                <Box>
                    <Heading as='h1' sx={styles.header}>
                        Sobre a LGDP - Lei Geral de Proteção de Dados Pessoais<br/>
                    </Heading>
                </Box>
                <Box>
                    <Heading as='h3' sx={styles.header}>
                        <br/>Coleta e uso de dados e registro de atividades
                    </Heading>
                </Box>
                <Box sx={styles.textContent}>
                    <Text>
                    Os dados pessoais são coletados a partir do consentimento do <strong>usuário</strong>, 
                    podendo ser enriquecidos posteriormente. Outros dados pessoais podem ser coletados de acordo com a natureza específica 
                    do produto ou serviço em questão.
                    </Text>
                </Box>
                <Box sx={styles.textContent}>
                    <Text>
                    A MOU não é responsável pela precisão, veracidade ou atualidade das informações prestadas pelo <strong>usuário</strong>, 
                    sendo de responsabilidade do <strong>usuário</strong> prestá-las com exatidão ou atualizá-las.
                    A MOU pode realizar atualização e enriquecimento dos dados.
                    A MOU garante ao <strong>usuário</strong> o direito de correção de dados incompletos, inexatos ou desatualizados.<br/>
                    </Text>
                </Box>
                <Box sx={styles.textContent}>
                    <Text>
                    A MOU utiliza as tecnologias compatíveis com o mercado, respeitando o estado da técnica razoável, 
                    com atualizações razoáveis e constantes, para registrar eventos de navegação, cliques, eventos e dados não identificáveis.
                    Todas as tecnologias utilizadas devem respeitar a legislação vigente e os termos aqui descritos.
                    </Text>
                </Box>
                <Box sx={styles.textContent}>
                    <Text>
                    O consentimento fornecido pelo <strong>usuário</strong> é coletado de forma individual, clara, específica e legítima. O <strong>usuário</strong> pode alterar suas concessões de consentimento, 
                    conceder novas permissões ou retirar seu consentimento para as emissões atuais por meio do <strong>Canal de Atendimento</strong> à LGPD da MOU, sendo avisado das consequências 
                    que a retirada de consentimento pode causar. O <strong>usuário</strong> pode entrar no site <strong><a href="https://mou.digital/">https://mou.digital/</a></strong> na opção <strong>Entre em Contato</strong>.<br/>
                    </Text>
                </Box>
                <Box sx={styles.textContent}>
                    <Text>
                     Os dados coletados e as atividades registradas também podem ser compartilhados:<br/> 
                    <strong>a)</strong> Com autoridades judiciais, administrativas ou governamentais competentes, nos casos de requerimento, requisição ou ordem judicial;<br/> 
                    <strong>b)</strong> Com os órgãos de controle que fiscalizam a MOU, quando solicitado;<br/> 
                    <strong>c)</strong> Em caso de transformações societárias, como fusão, aquisição e incorporação, sempre observado o princípio da transparência com o <strong>usuário</strong>.<br/> 
                    </Text>
                </Box>
                <Box sx={styles.textContent}>
                    <Text>
                    A base de dados, formada por meio da coleta de dados, é de propriedade e responsabilidade da MOU. Seu uso, acesso e compartilhamento, 
                    quando necessários, serão feitos dentro dos limites e propósitos dos negócios da MOU e descritos em nossos <strong>Termos de Uso</strong>.
                    O <strong>usuário</strong> é corresponsável pelo sigilo e pela confidencialidade de seus dados pessoais. O compartilhamento de senhas ou dados de acesso viola os termos da MOU.
                    </Text>
                </Box>
                <Box sx={styles.textContent}>
                    <Text>
                    Internamente os dados dos <strong>usuários</strong> são acessados somente por profissionais devidamente autorizados pela MOU sendo eles empregados, prestadores de serviços e parceiros.
                    A MOU respeita os princípios de proporcionalidade, necessidade e relevância, além de firmar o compromisso de confidencialidade e preservação da privacidade com os <strong>usuários</strong>. 
                    </Text>
                </Box>
                <Box>
                    <Heading as='h3' sx={styles.header}>
                        Armazenamento dos dados e registros 
                    </Heading>
                </Box>
                <Box sx={styles.textContent}>
                    <Text>
                        Os dados coletados e os registros de atividades devem ser armazenados em ambiente seguro e controlado pelo prazo mínimo estipulado conforme abaixo: 
                    </Text>
                </Box>
                <Box sx={styles.textContent}>
                    <Text>
                        <strong>Dados cadastrais</strong> - 10 anos após o término da relação - Arts. 12 e 34 do Código de Defesa do Consumidor (Lei 8.078/90)<br/>
                    </Text>
                    <Text>
                        <strong>Dados de identificação digital</strong> - 6 meses do último acesso - Art. 15 do Marco Civil da Internet (Lei 12.965/14)                    
                    </Text>
                </Box>
                <Box sx={styles.textContent}>
                    <Text>
                    Os dados podem ser excluídos antes desse prazo no caso de solicitação do <strong>usuário</strong>.<br/> Os dados podem ser mantidos por período superior ao previsto neste prazo, por motivo previsto em lei, 
                    por decisão judicial, para fins de prestação de contas aos órgãos de controle ou por outros interesses legítimos da MOU, devidamente especificados. Findo o prazo e a necessidade legal, 
                    os dados devem ser excluídos com uso de métodos de descarte seguro, ou utilizados de forma anonimizada para fins estatísticos. 
                    Os dados coletados são armazenados em servidores próprios ou em provedores de serviços em nuvem que atendam aos controles de proteção de dados pessoais aplicáveis.
                    </Text>
                </Box>
                <Box>
                    <Heading as='h3' sx={styles.header}>
                        Confirmação da existência de tratamento e acesso aos dados, correção, bloqueio, oposição e eliminação de dados 
                    </Heading>
                </Box>
                <Box sx={styles.textContent}>
                    <Text>
                    O <strong>usuário</strong> pode solicitar a confirmação da existência de tratamento, o acesso aos seus dados pessoais ou a correção de seus dados pessoais, por meio do site da MOU, 
                    disponível em <strong><a href="https://mou.digital">https://mou.digital</a></strong> na opção <strong>Entre em Contato</strong>. Pelo canal de Contato, o <strong>usuário</strong> pode também:<br/>
                    </Text>
                    <Text>
                        <strong>(i)</strong> requerer o bloqueio do uso de seus dados pessoais;<br/>
                    </Text>
                    <Text>
                        <strong>(ii)</strong> manifestar oposição ao tratamento de seus dados pessoais realizado com fundamento em uma das hipóteses de dispensa de consentimento,<br/>
                    </Text>
                    <Text>
                        <strong>(iii)</strong> solicitar a eliminação de seus dados pessoais coletados e registrados pela MOU ao término de sua finalidade de uso ou<br/>
                    </Text>
                    <Text>
                        <strong>(iv)</strong> solicitar a revisão de decisões automatizadas.<br/>
                    </Text>
                    <Text>
                    <br/>Caso o <strong>usuário</strong> retire seu consentimento para finalidades fundamentais ao regular funcionamento dos sistemas da MOU, os serviços e funcionalidades dos sistemas, de acordo com as suas características específicas, 
                    podem ficar indisponíveis para esse <strong>usuário</strong>.<br/> Caso o <strong>usuário</strong> não conceda seu consentimento para as finalidades facultativas 
                    (relacionadas ao envio de informações, novidades, funcionalidades, conteúdos, notícias e eventos), 
                    a prestação de serviços pela MOU por meio de seus sistemas deve continuar sendo feita regularmente em relação às finalidades principais.<br/> 
                    Para fins de auditoria, segurança, controle de fraudes e preservação de direitos, a MOU pode permanecer com o histórico de registro e os dados dos <strong>usuários</strong> por prazo maior nas hipóteses que a lei ou 
                    norma regulatória assim estabelecer, para realização de prestação de contas aos órgãos de controle, ou para preservação de direitos da MOU ou do titular de dados. 
                    </Text>
                </Box>
                <Box>
                    <Heading as='h3' sx={styles.header}>
                        Disposições gerais   
                    </Heading>
                </Box>
                <Box sx={styles.textContent}>
                    <Text>
                    A MOU reserva a si o direito de alterar Documentos e as Políticas de Privacidade e Termo de Uso a qualquer momento, conforme a finalidade ou a necessidade, como para adequação e conformidade com disposição de lei ou 
                    norma que tenha força jurídica equivalente, cabendo ao <strong>usuário</strong> verificar a versão atualizada sempre que efetuar o acesso aos sistemas da MOU. 
                    </Text>
                </Box>
                <Box sx={styles.textContent}>
                    <Text>
                    Ocorrendo atualizações nos documentos e que demandem nova coleta de consentimento, a MOU deve notificar o <strong>usuário</strong> pelos seus sistemas ou pelos meios de contato por ele fornecidos. 
                    Em caso de qualquer dúvida com relação às disposições constantes nas nossas Políticas, o <strong>usuário</strong> pode entrar em contato com a MOU no 
                    site <strong><a href="https://mou.digital">https://mou.digital</a></strong> na opção <strong>Entre em Contato</strong>.
                    </Text>
                </Box>
                <Box sx={styles.textContent}>
                    <Text>
                    Caso empresas terceirizadas realizem o tratamento de quaisquer dados coletados pela MOU, devem respeitar as condições aqui estipuladas e as normas de Segurança da Informação da MOU, bem como Políticas e 
                    procedimentos adicionais. Caso alguma disposição destas Políticas seja considerada ilegal ou ilegítima por autoridade de proteção de dados, as demais condições permanecem em pleno vigor e efeito.<br/> 
                    O <strong>usuário</strong> reconhece que toda comunicação realizada por e-mail (aos endereços informados no seu cadastro), SMS, aplicativos de comunicação instantânea ou qualquer outra forma digital, virtual e digital também são 
                    válidas como prova documental, sendo eficazes e suficientes para a divulgação de qualquer assunto que se refere aos serviços prestados pela MOU, bem como às condições de sua prestação ou a 
                    qualquer outro assunto nele abordado, ressalvadas as disposições expressamente diversas previstas nas nossas Políticas. 
                    </Text>
                </Box>
                <Box sx={styles.textContent}>
                    <Text>
                    A MOU utiliza cookies e tecnologias similares para avaliar e compreender o perfil e o comportamento dos usuários que visitam ou acessam as páginas, com a finalidade de personalizar a experiência de 
                    navegação do usuário ao promover e divulgar produtos e serviços, além de realizar prevenção a fraudes.
                    </Text>
                </Box>
                <Box>
                    <Heading as='h3' sx={styles.header}>
                        Lei aplicável e jurisdição  
                    </Heading>
                </Box>
                <Box sx={styles.textContent}>
                    <Text>
                    Os documentos e políticas devem ser regidos e interpretados segundo a legislação brasileira, no idioma português, sendo eleito o Foro da cidade de <strong>Florianópolis/SC</strong> para dirimir qualquer 
                    litígio ou controvérsia envolvendo este e outros presente documentos, salvo ressalva específica de competência pessoal, territorial ou funcional pela legislação aplicável.
                    </Text>
                </Box>
                <Box>
                    <Heading as='h3' sx={styles.header}>
                        Glossário  
                    </Heading>
                </Box>
                <Box sx={styles.textContent}>
                    <Text>
                        Neste documento devem se considerar as seguintes definições:<br/> 
                    </Text>
                    <Text>
                    <strong>Cookies:</strong> Pequenos arquivos enviados pelos sistemas da MOU, gravados nos dispositivos dos <strong>usuários</strong>, que armazenam as preferências e outras informações, 
                    com a finalidade de personalizar a navegação dos <strong>usuários</strong> no Portal, de acordo com o seu perfil. 
                    </Text>
                </Box>
            </Container>
        </section>
    )
};

export default LgpdContent;

const styles = {
    header: {
        color: 'primary'
    },
    lgpdImage: {
        alignItems: 'center',
        mt: [19, null, null, null, 10],
        img: {
            m: [null, null, null, '0 auto', 0],
            width: [200,400,600],
            height: [200,400,600]
        }
    },
    textContent:{
        mt: 30,
        mb:30
    }
};
