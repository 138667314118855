import React from 'react';
import Layout from 'components/layout';
import SEO from 'components/seo';
import LgpdContent from 'sections/LgpdContent';

const LgpdPage = () => {
    var metaTag = {
        name: 'robots',
        content: 'noindex,nofollow'
    };

    return(
        <Layout showHeader={true} showFooter={true}>
             <SEO
                title="MOU - Modelagem Orientada ao Usuário"
                description="MOU - Modelagem Orientada ao Usuário"
                meta={metaTag}
            />
            <LgpdContent/>
        </Layout>
    )
};
   


export default LgpdPage;